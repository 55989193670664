import React from "react";
import Button from "../Button";
import { IonModal } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import styled from "styled-components";

const Modal: React.FC<{
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  doClose: () => void;
  isSwipeToClose?: boolean;
  isAnimated?: boolean;
  defaultHeader?: boolean;
  onDismiss?: () => void;
  initialBreakpoint?: number
  breakpoints?: Array<number>
  swipeToClose?: boolean
  className?: string
  mode?: "ios" | "md"
  styles?: any
  ref?: any
}> = (props) => {
  const onDismiss = () => {
    if (props.onDismiss) props.onDismiss();
    else props.setIsVisible(false);
  };

  return (
    <ModalStyleWrapper>
      <IonModal
        isOpen={props.isVisible}
        onDidDismiss={onDismiss}
        animated={props.isAnimated}
        className={`modal ${props.className}`}
        initialBreakpoint={props.initialBreakpoint}
        breakpoints={props.breakpoints}
        canDismiss={props.swipeToClose}
        mode={props.mode}
        style={props.styles}
        ref={props.ref}
        aria-hidden="false"
      >
        {props.defaultHeader && (
          <Button
            variants={"secondary_noLine"}
            onClick={props.doClose}
            icon={arrowBack}
            iconDir={"icon-only"}
          ></Button>
        )}
        {props.children}
      </IonModal>
    </ModalStyleWrapper>
  );
};

const ModalStyleWrapper = styled.div`
  .fullscreen {
    --min-height: 100%;
    --min-width: 100%;
  }
`;

export default Modal;
