/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyOrderFuneralInfoToMemoryRoom
// ====================================================

export interface CopyOrderFuneralInfoToMemoryRoom {
  /**
   * Extract the funeral info text and pass it to memoryroom
   */
  copyOrderFuneralInfoToMemoryRoom: string;
}

export interface CopyOrderFuneralInfoToMemoryRoomVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrder
// ====================================================

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_client_route_editPageRoute {
  clientApp: ApplicationClientApps;
  absUrl: string;
  relUrl: string;
  taps5IframeAbsUrl: string | null;
  taps5RelUrl: string;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_client_route {
  editPageRoute: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_client_route_editPageRoute;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_client {
  route: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_client_route;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
  documentType: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_documentFormat_documentType;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_documentFormat;
  /**
   * media of the order
   */
  media: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder_status;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  client: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_client | null;
  alerting: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: DeleteOrder_deleteOrder_caseFolders_editTabs_orders_document;
}

export interface DeleteOrder_deleteOrder_caseFolders_editTabs {
  id: string;
  name: string;
  isSubTabs: boolean;
  orders: DeleteOrder_deleteOrder_caseFolders_editTabs_orders[];
}

export interface DeleteOrder_deleteOrder_caseFolders_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface DeleteOrder_deleteOrder_caseFolders_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface DeleteOrder_deleteOrder_caseFolders_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_documentFormat_documentType;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_media;
  /**
   * information for the editors
   */
  edit: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder_status;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_document;
  alerting: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders_mediaOrder | null;
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_documentFormat;
  orders: DeleteOrder_deleteOrder_caseFolders_productPackages_documents_orders[];
}

export interface DeleteOrder_deleteOrder_caseFolders_productPackages {
  /**
   * Product Package
   */
  productPackage: DeleteOrder_deleteOrder_caseFolders_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: DeleteOrder_deleteOrder_caseFolders_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (DeleteOrder_deleteOrder_caseFolders_productPackages_documents | null)[];
}

export interface DeleteOrder_deleteOrder_caseFolders {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  editTabs: DeleteOrder_deleteOrder_caseFolders_editTabs[];
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: DeleteOrder_deleteOrder_caseFolders_customer;
  propertiesFuneralStandard: DeleteOrder_deleteOrder_caseFolders_propertiesFuneralStandard;
  summary: DeleteOrder_deleteOrder_caseFolders_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (DeleteOrder_deleteOrder_caseFolders_productPackages | null)[];
}

export interface DeleteOrder_deleteOrder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  caseFolders: DeleteOrder_deleteOrder_caseFolders[];
}

export interface DeleteOrder {
  /**
   * Delete an order
   */
  deleteOrder: DeleteOrder_deleteOrder;
}

export interface DeleteOrderVariables {
  input: MutationDeleteOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_loginByCredential_user_customers {
  /**
   * The id of customer
   */
  id: string;
}

export interface Login_loginByCredential_user {
  customers: (Login_loginByCredential_user_customers | null)[] | null;
}

export interface Login_loginByCredential {
  id: string;
  isAuthenticated: boolean;
  /**
   * The authorization-token for putting in the Authorization header when calling for the api
   */
  authorizationToken: string;
  user: Login_loginByCredential_user | null;
}

export interface Login {
  /**
   * Login an user by credentials
   */
  loginByCredential: Login_loginByCredential;
}

export interface LoginVariables {
  userCredentialInput: UserCredentialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MediateMaterial
// ====================================================

export interface MediateMaterial_messagingOrdersMaterials_material_proof {
  /**
   * Url to the material
   */
  url: string | null;
}

export interface MediateMaterial_messagingOrdersMaterials_material_galleryImages {
  /**
   * Url to the material
   */
  url: string;
}

export interface MediateMaterial_messagingOrdersMaterials_material_orders_previewPng {
  /**
   * Url to the material
   */
  url: string;
}

export interface MediateMaterial_messagingOrdersMaterials_material_orders {
  previewPng: MediateMaterial_messagingOrdersMaterials_material_orders_previewPng | null;
}

export interface MediateMaterial_messagingOrdersMaterials_material {
  /**
   * Proof
   */
  proof: MediateMaterial_messagingOrdersMaterials_material_proof;
  galleryImages: MediateMaterial_messagingOrdersMaterials_material_galleryImages[];
  /**
   * Material per Order
   */
  orders: MediateMaterial_messagingOrdersMaterials_material_orders[];
}

export interface MediateMaterial_messagingOrdersMaterials {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  material: MediateMaterial_messagingOrdersMaterials_material;
}

export interface MediateMaterial {
  /**
   * Email endcustomer with materials
   */
  messagingOrdersMaterials: MediateMaterial_messagingOrdersMaterials;
}

export interface MediateMaterialVariables {
  input: MutationMessagingOrdersMaterialsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MessagingOrderInfo
// ====================================================

export interface MessagingOrderInfo_messagingOrderInfo {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  /**
   * Stack
   */
  errorStack: string | null;
}

export interface MessagingOrderInfo {
  /**
   * Email endcustomer with order information
   */
  messagingOrderInfo: MessagingOrderInfo_messagingOrderInfo;
}

export interface MessagingOrderInfoVariables {
  input: OrderCollectionMessagingInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublishOrders
// ====================================================

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder_edit_userInterface {
  editStatusIcon: EditUIEditStatusIcon;
  editStatusText: string | null;
  publishButtonText: string | null;
}

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: PublishOrders_publishOrders_resultOrders_order_mediaOrder_edit_userInterface;
}

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder_publishConversation {
  /**
   * Order is alread
   */
  isBookingSent: boolean;
}

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder {
  /**
   * information for the editors
   */
  edit: PublishOrders_publishOrders_resultOrders_order_mediaOrder_edit;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Status of the order
   */
  status: PublishOrders_publishOrders_resultOrders_order_mediaOrder_status;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: PublishOrders_publishOrders_resultOrders_order_mediaOrder_publishConversation;
}

export interface PublishOrders_publishOrders_resultOrders_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: PublishOrders_publishOrders_resultOrders_order_mediaOrder | null;
}

export interface PublishOrders_publishOrders_resultOrders {
  success: boolean;
  resultIcon: MessageBoxIcons;
  resultText: string | null;
  order: PublishOrders_publishOrders_resultOrders_order;
}

export interface PublishOrders_publishOrders {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  isAddedMemoryRoom: boolean;
  isPublishMemoryRoom: boolean;
  isPublishMemoryRoomError: boolean;
  publishMemoryRoomErrorText: string | null;
  resultText: string | null;
  resultIcon: MessageBoxIcons;
  /**
   * Reasons
   */
  errorReason: string | null;
  resultOrders: PublishOrders_publishOrders_resultOrders[];
}

export interface PublishOrders {
  /**
   * Publish/Send orders
   */
  publishOrders: PublishOrders_publishOrders;
}

export interface PublishOrdersVariables {
  input: MutationPublishOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReActivateUnPublishOrders
// ====================================================

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_edit_userInterface {
  editStatusText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  editStatusColor: EditUIEditStatusColor;
  publishButtonText: string | null;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_edit_userInterface;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_publishConversation {
  /**
   * Order is alread
   */
  isBookingSent: boolean;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_edit;
  /**
   * Status of the order
   */
  status: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_status;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_publishConversation;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder | null;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  orders: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders[];
}

export interface ReActivateUnPublishOrders {
  /**
   * Reactivate UnPublish orders
   */
  reactivateUnPublishOrders: ReActivateUnPublishOrders_reactivateUnPublishOrders;
}

export interface ReActivateUnPublishOrdersVariables {
  input: MutationReactivateUnPublishOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetExhibitOrder
// ====================================================

export interface SetExhibitOrder {
  /**
   * Set what order should it exhibit with
   */
  setExhibitOrder: string;
}

export interface SetExhibitOrderVariables {
  caseFolderId: string;
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TouchCaseFolder
// ====================================================

export interface TouchCaseFolder_touchCaseFolder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
}

export interface TouchCaseFolder {
  /**
   * Touch the casefolder
   */
  touchCaseFolder: TouchCaseFolder_touchCaseFolder;
}

export interface TouchCaseFolderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnPublishOrders
// ====================================================

export interface UnPublishOrders_unPublishOrders_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UnPublishOrders_unPublishOrders_orders_mediaOrder_publishConversation {
  /**
   * Order is alread
   */
  isBookingSent: boolean;
}

export interface UnPublishOrders_unPublishOrders_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Status of the order
   */
  status: UnPublishOrders_unPublishOrders_orders_mediaOrder_status;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UnPublishOrders_unPublishOrders_orders_mediaOrder_publishConversation;
}

export interface UnPublishOrders_unPublishOrders_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UnPublishOrders_unPublishOrders_orders_mediaOrder | null;
}

export interface UnPublishOrders_unPublishOrders {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  orders: UnPublishOrders_unPublishOrders_orders[];
}

export interface UnPublishOrders {
  /**
   * UnPublish orders
   */
  unPublishOrders: UnPublishOrders_unPublishOrders;
}

export interface UnPublishOrdersVariables {
  input: MutationUnPublishOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCaseFolder
// ====================================================

export interface UpdateCaseFolder_updateCaseFolder_caseFolder_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface UpdateCaseFolder_updateCaseFolder_caseFolder_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
}

export interface UpdateCaseFolder_updateCaseFolder_caseFolder_productPackages {
  /**
   * Product Package
   */
  productPackage: UpdateCaseFolder_updateCaseFolder_caseFolder_productPackages_productPackage;
}

export interface UpdateCaseFolder_updateCaseFolder_caseFolder_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface UpdateCaseFolder_updateCaseFolder_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: UpdateCaseFolder_updateCaseFolder_caseFolder_customer;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (UpdateCaseFolder_updateCaseFolder_caseFolder_productPackages | null)[];
  propertiesFuneralStandard: UpdateCaseFolder_updateCaseFolder_caseFolder_propertiesFuneralStandard;
}

export interface UpdateCaseFolder_updateCaseFolder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  caseFolder: UpdateCaseFolder_updateCaseFolder_caseFolder;
}

export interface UpdateCaseFolder {
  /**
   * Update casefolder
   */
  updateCaseFolder: UpdateCaseFolder_updateCaseFolder;
}

export interface UpdateCaseFolderVariables {
  input: MutationUpdateCaseFolderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCustomerDocumentContentInitiatorFromOrder
// ====================================================

export interface UpdateCustomerDocumentContentInitiatorFromOrder {
  /**
   * Update customer document content initiator
   */
  updateCustomerDocumentContentInitiatorFromOrder: string;
}

export interface UpdateCustomerDocumentContentInitiatorFromOrderVariables {
  customerScopeForDocumentContent: CustomerScope;
  orderId: string;
  replaceLowerCustomerScopeInitiors: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderContent
// ====================================================

export interface UpdateOrderContent_updateOrderContent_order_document {
  /**
   * Id of the document
   */
  id: string | null;
  __typename: "Document";
}

export interface UpdateOrderContent_updateOrderContent_order_client_route {
  taps4Url: string;
  __typename: "OrderClientRoute";
}

export interface UpdateOrderContent_updateOrderContent_order_client {
  mainSystem: SystemFlags;
  isTaps4: boolean;
  isTaps5: boolean;
  route: UpdateOrderContent_updateOrderContent_order_client_route;
  __typename: "OrderClient";
}

export interface UpdateOrderContent_updateOrderContent_order_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
  __typename: "OrderAlerting";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
  __typename: "MediaOrderPublishDay";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
  __typename: "DocumentFormatCollection";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
  __typename: "DocumentType";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat_documentType;
  __typename: "DocumentFormat";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
  __typename: "MediaOrderPublishConversation";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
  __typename: "MediaOrderPublishChannels";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
  __typename: "Size";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_basePage {
  size: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_basePage_size | null;
  __typename: "MediaOrderMaterialPage";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_pages_png;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_pages | null)[];
  __typename: "MediaOrderMaterial";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order
   */
  pricePay: any | null;
  __typename: "PriceDetailScope";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price_endCustomer;
  __typename: "PriceDetail";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview {
  /**
   * material
   */
  material: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material;
  __typename: "MediaOrderPreview";
  /**
   * price of the order
   */
  price: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  __typename: "EditUI";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
  __typename: "EditInfoResources";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_entries {
  memoryRoomUrl: string;
  voucherKey: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_case_personTimeBorn {
  value: any | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_case_personTimeDied {
  value: any | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_case {
  personFirstname: string;
  personLastname: string;
  personBornLastname: string;
  personCityDied: string;
  personTimeBorn: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_case_personTimeBorn;
  personTimeDied: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_case_personTimeDied;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_features {
  isLightningCandle: boolean;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage_value {
  id: string;
  imageUrl: string;
  name: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage {
  value: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage_value | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_contents_images {
  id: string;
  imageUrl: string;
  name: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_contents {
  welcomeText: string;
  avatarImage: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage | null;
  images: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_contents_images[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_lastBookingTime {
  value: any | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_time {
  value: any | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies {
  lastBookingTime: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_lastBookingTime;
  time: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_time;
  locationText: string;
  infoText: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_funeral {
  ceremonies: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom {
  id: string;
  entries: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_entries | null;
  case: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_case;
  features: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_features;
  contents: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_contents;
  funeral: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom_funeral;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelLiveStream_advanced {
  playerUrl: string | null;
  ingestionUrl: string | null;
  streamName: string | null;
  RecorderLarix500: string | null;
  RecorderLarix1000: string | null;
  RecorderLarix1500: string | null;
  RecorderLarix2000: string | null;
  GoCoderIOS: string | null;
  GoCoderAndriod: string | null;
  qrRecorderLarix500: string | null;
  qrRecorderLarix1000: string | null;
  qrRecorderLarix1500: string | null;
  qrRecorderLarix2000: string | null;
  qrGoCoderIOS: string | null;
  qrGoCoderAndriod: string | null;
  GoCoderAndroid: string | null;
  __typename: "EditModelLiveStreamAdvanced";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelLiveStream {
  isStored: boolean;
  isActivated: boolean;
  timeLive: any | null;
  waitImageUrl: string | null;
  ceremonyAgendaUrl: string | null;
  advanced: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelLiveStream_advanced;
  __typename: "EditModelLiveStream";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  margin: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines {
  /**
   * Textline in plantext.
   */
  class: string | null;
  /**
   * Textline in plantext.
   */
  text: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in plantext.
   */
  plainText: string | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Text lines
   */
  lines: (UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines | null)[] | null;
  /**
   * Font
   */
  fontSize: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Block styles
   */
  blockStyles: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Common parameters
   */
  common: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  value: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Letter spacing
   */
  letterSpacing: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidth: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  content: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
  __typename: "EditModelAdvertArea";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage {
  areas: (UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
  __typename: "EditModelAdvertBasePage";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage;
  __typename: "EditModelAdvert";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
  __typename: "EditHistory";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_userInterface;
  resources: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_resources | null;
  editModelMemoryRoom: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelMemoryRoom | null;
  editModelLiveStream: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelLiveStream | null;
  editModelAdvert: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert | null;
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
  history: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_history;
  __typename: "EditInfo";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
  __typename: "Media";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  __typename: "MediaOrderStatus";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (UpdateOrderContent_updateOrderContent_order_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UpdateOrderContent_updateOrderContent_order_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: UpdateOrderContent_updateOrderContent_order_mediaOrder_publishChannels;
  /**
   * Preview of the order
   */
  preview: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview;
  /**
   * information for the editors
   */
  edit: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit;
  /**
   * media of the order
   */
  media: UpdateOrderContent_updateOrderContent_order_mediaOrder_media;
  /**
   * Status of the order
   */
  status: UpdateOrderContent_updateOrderContent_order_mediaOrder_status;
  __typename: "MediaOrder";
}

export interface UpdateOrderContent_updateOrderContent_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: UpdateOrderContent_updateOrderContent_order_document;
  client: UpdateOrderContent_updateOrderContent_order_client | null;
  alerting: UpdateOrderContent_updateOrderContent_order_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateOrderContent_updateOrderContent_order_mediaOrder | null;
  __typename: "Order";
}

export interface UpdateOrderContent_updateOrderContent {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  order: UpdateOrderContent_updateOrderContent_order;
  __typename: "MutationUpdateOrderContentResponse";
}

export interface UpdateOrderContent {
  /**
   * Update contentModel in order
   */
  updateOrderContent: UpdateOrderContent_updateOrderContent;
}

export interface UpdateOrderContentVariables {
  input: MutationUpdateOrderContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderLock
// ====================================================

export interface UpdateOrderLock_lockOrder_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UpdateOrderLock_lockOrder_order_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Status of the order
   */
  status: UpdateOrderLock_lockOrder_order_mediaOrder_status;
  __typename: "MediaOrder";
}

export interface UpdateOrderLock_lockOrder_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateOrderLock_lockOrder_order_mediaOrder | null;
}

export interface UpdateOrderLock_lockOrder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  order: UpdateOrderLock_lockOrder_order;
}

export interface UpdateOrderLock {
  /**
   * Lock an order
   */
  lockOrder: UpdateOrderLock_lockOrder;
}

export interface UpdateOrderLockVariables {
  input: MutationLockOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePublishTime
// ====================================================

export interface UpdatePublishTime_updateOrderPublishTimes_order_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
  __typename: "Size";
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_basePage {
  size: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_basePage_size | null;
  __typename: "MediaOrderMaterialPage";
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_pages_png;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_pages | null)[];
  __typename: "MediaOrderMaterial";
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_price_endCustomer;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview {
  /**
   * material
   */
  material: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material;
  __typename: "MediaOrderPreview";
  /**
   * price of the order
   */
  price: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_price | null;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder {
  /**
   * Preview of the order
   */
  preview: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishChannels;
  /**
   * times when the order will be published
   */
  publishTimes: (UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishTimes | null)[];
}

export interface UpdatePublishTime_updateOrderPublishTimes_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  alerting: UpdatePublishTime_updateOrderPublishTimes_order_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder | null;
}

export interface UpdatePublishTime_updateOrderPublishTimes {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  order: UpdatePublishTime_updateOrderPublishTimes_order;
}

export interface UpdatePublishTime {
  /**
   * Update order publish times
   */
  updateOrderPublishTimes: UpdatePublishTime_updateOrderPublishTimes;
}

export interface UpdatePublishTimeVariables {
  input: MutationUpdateOrderPublishTimesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetArchiveCaseFolders
// ====================================================

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  __typename: "CaseFolderPropertiesFuneralStandard";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  __typename: "Customer";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
  __typename: "OrderInitiatorPackage";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
  friendlyName: string;
  __typename: "DocumentType";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat_documentType;
  __typename: "DocumentFormat";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
  __typename: "OrderAlerting";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
  __typename: "Media";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
  __typename: "DocumentFormat";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
  __typename: "MediaOrderPublishDay";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  __typename: "MediaOrderStatus";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder {
  /**
   * media of the order
   */
  media: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_media;
  /**
   * documentformat of the order
   */
  documentFormat: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_status;
  __typename: "MediaOrder";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  alerting: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder | null;
  __typename: "Order";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat;
  orders: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders[];
  __typename: "Document";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages {
  /**
   * Product Package
   */
  productPackage: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_productPackage;
  /**
   * orders of the product package
   */
  documents: (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents | null)[];
  __typename: "DocumentProductPackage";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
  __typename: "OrdersSummary";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The name of the caseFolder
   */
  name: string;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  propertiesFuneralStandard: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard;
  customer: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages | null)[];
  summary: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_summary;
  __typename: "CaseFolder";
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer {
  /**
   * The total number of documentFolders in the search
   */
  count: number;
  /**
   * The startindex for the search
   */
  startIndex: number;
  caseFolders: (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders | null)[] | null;
  __typename: "FindCaseFolderList";
}

export interface GetArchiveCaseFolders {
  /**
   * Get case folders for customer
   */
  findCaseFoldersByCurrentCustomer: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer;
}

export interface GetArchiveCaseFoldersVariables {
  scope?: CaseFolderCustomerScope | null;
  filterByOrderStatus?: OrderStatus | null;
  textFilter?: string | null;
  startindex: number;
  pageLength: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailablePublishTimes
// ====================================================

export interface GetAvailablePublishTimes_mediaPublishCalendarDays {
  /**
   * Day in the Calendar
   */
  timeDay: any;
  /**
   * Allowed to publish
   */
  isEnabled: boolean;
  /**
   * Time when last booking can be done
   */
  timeBooking: any | null;
  __typename: "MediaPublishCalendarDay";
}

export interface GetAvailablePublishTimes {
  /**
   * Get booking calendar
   */
  mediaPublishCalendarDays: GetAvailablePublishTimes_mediaPublishCalendarDays[];
}

export interface GetAvailablePublishTimesVariables {
  orderId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailableTags
// ====================================================

export interface GetAvailableTags_galleryImagesTagsByTags {
  tags: string[];
  __typename: "GalleryImageAvailableTagsResult";
}

export interface GetAvailableTags {
  /**
   * Get images-Tags filter by tags
   */
  galleryImagesTagsByTags: GetAvailableTags_galleryImagesTagsByTags;
}

export interface GetAvailableTagsVariables {
  input: GalleryImageAvailableTagsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCalendarOrders
// ====================================================

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder {
  /**
   * Status of the order
   */
  status: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder_status;
  /**
   * media of the order
   */
  media: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder_media;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_document_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The name of the caseFolder
   */
  name: string;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * caseFolder of the document
   */
  caseFolder: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_document_caseFolder;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders {
  orderDate: any | null;
  /**
   * The id of the mediaorder
   */
  id: string | null;
  alerting: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_document;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer {
  /**
   * Calenderday
   */
  date: any;
  summary: GetCalendarOrders_calendarOrdersByCurrentCustomer_summary;
  /**
   * Orders for the calenderday to view
   */
  orders: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders[];
}

export interface GetCalendarOrders {
  /**
   * Get order by calender days
   */
  calendarOrdersByCurrentCustomer: GetCalendarOrders_calendarOrdersByCurrentCustomer[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentCustomer
// ====================================================

export interface GetCurrentCustomer_currentCustomer_colleagues_inheritSettings {
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentCustomer_currentCustomer_colleagues_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
  __typename: "CustomerOffice";
}

export interface GetCurrentCustomer_currentCustomer_colleagues_primaryUser {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * The username of user
   */
  username: string;
  /**
   * The account is enabled or not
   */
  enabled: boolean;
  __typename: "User";
}

export interface GetCurrentCustomer_currentCustomer_colleagues {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  /**
   * The firstname of customer
   */
  firstName: string;
  /**
   * The lastname of customer
   */
  lastName: string;
  inheritSettings: GetCurrentCustomer_currentCustomer_colleagues_inheritSettings;
  office: GetCurrentCustomer_currentCustomer_colleagues_office;
  /**
   * The primary user for the customer
   */
  primaryUser: GetCurrentCustomer_currentCustomer_colleagues_primaryUser | null;
  __typename: "Customer";
}

export interface GetCurrentCustomer_currentCustomer_settings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentCustomer_currentCustomer_inheritSettings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentCustomer_currentCustomer_office_settings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentCustomer_currentCustomer_office_inheritSettings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentCustomer_currentCustomer_office_company_settings {
  /**
   * The emailaddress using for invoicing
   */
  emailInvoicing: string | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentCustomer_currentCustomer_office_company_inheritSettings {
  /**
   * The emailaddress using for invoicing
   */
  emailInvoicing: string | null;
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_product {
  /**
   * Id of the product
   */
  id: string;
  /**
   * Name of the product
   */
  name: string;
  __typename: "Product";
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_contractCustomer {
  /**
   * Price added on the proofs. The customer earnings
   */
  price: any;
  __typename: "CustomerContractCustomerProduct";
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_contractEndCustomer {
  /**
   * Price added on the proofs. The customer earnings
   */
  price: any;
  __typename: "CustomerContractEndCustomerProduct";
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts {
  /**
   * Product
   */
  product: GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_product;
  /**
   * Customer contract
   */
  contractCustomer: GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_contractCustomer;
  /**
   * EndCustomer contract
   */
  contractEndCustomer: GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_contractEndCustomer;
  __typename: "CustomerContractProduct";
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract {
  /**
   * Id of the contract
   */
  id: string;
  /**
   * Products in the contract
   */
  customerProducts: GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts[];
  __typename: "CustomerContract";
}

export interface GetCurrentCustomer_currentCustomer_office_company_offices {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
  __typename: "CustomerOffice";
}

export interface GetCurrentCustomer_currentCustomer_office_company {
  /**
   * The id of company
   */
  id: string;
  settings: GetCurrentCustomer_currentCustomer_office_company_settings;
  inheritSettings: GetCurrentCustomer_currentCustomer_office_company_inheritSettings;
  customerContract: GetCurrentCustomer_currentCustomer_office_company_customerContract;
  offices: GetCurrentCustomer_currentCustomer_office_company_offices[];
  __typename: "CustomerCompany";
}

export interface GetCurrentCustomer_currentCustomer_office {
  /**
   * The id of office
   */
  id: string;
  settings: GetCurrentCustomer_currentCustomer_office_settings;
  inheritSettings: GetCurrentCustomer_currentCustomer_office_inheritSettings;
  company: GetCurrentCustomer_currentCustomer_office_company;
  __typename: "CustomerOffice";
}

export interface GetCurrentCustomer_currentCustomer {
  /**
   * The id of customer
   */
  id: string;
  colleagues: (GetCurrentCustomer_currentCustomer_colleagues | null)[];
  settings: GetCurrentCustomer_currentCustomer_settings;
  inheritSettings: GetCurrentCustomer_currentCustomer_inheritSettings;
  office: GetCurrentCustomer_currentCustomer_office;
  __typename: "Customer";
}

export interface GetCurrentCustomer {
  /**
   * Get current customer
   */
  currentCustomer: GetCurrentCustomer_currentCustomer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentUserSession
// ====================================================

export interface GetCurrentUserSession_currentUserSession_client_route {
  taps4UrlHome: string;
  __typename: "UserSessionClientRoute";
}

export interface GetCurrentUserSession_currentUserSession_client {
  route: GetCurrentUserSession_currentUserSession_client_route;
  __typename: "UserSessionClient";
}

export interface GetCurrentUserSession_currentUserSession_user_customers_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
  __typename: "CustomerOffice";
}

export interface GetCurrentUserSession_currentUserSession_user_customers {
  /**
   * The id of customer
   */
  id: string;
  office: GetCurrentUserSession_currentUserSession_user_customers_office;
  __typename: "Customer";
}

export interface GetCurrentUserSession_currentUserSession_user {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * The username of user
   */
  username: string;
  /**
   * CultureCode for the user
   */
  cultureCode: string;
  /**
   * The user who cannot do any harm in the system
   */
  isDemoUser: boolean;
  /**
   * The user who has extra privilege in the system
   */
  isPowerUserOffice: boolean;
  /**
   * The owner of the customerCompany
   */
  isPowerUserCompany: boolean;
  /**
   * The user, helping others
   */
  isAssistentUser: boolean;
  /**
   * The timecut user helping the customer
   */
  isSupportUser: boolean;
  /**
   * The timecut user who has most privilege in the system
   */
  isSuperUser: boolean;
  customers: (GetCurrentUserSession_currentUserSession_user_customers | null)[] | null;
  __typename: "User";
}

export interface GetCurrentUserSession_currentUserSession_customer_settings {
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentUserSession_currentUserSession_customer_colleagues_inheritSettings {
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentUserSession_currentUserSession_customer_colleagues_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
  __typename: "CustomerOffice";
}

export interface GetCurrentUserSession_currentUserSession_customer_colleagues_primaryUser {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * The username of user
   */
  username: string;
  /**
   * The account is enabled or not
   */
  enabled: boolean;
  __typename: "User";
}

export interface GetCurrentUserSession_currentUserSession_customer_colleagues {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  /**
   * The firstname of customer
   */
  firstName: string;
  /**
   * The lastname of customer
   */
  lastName: string;
  inheritSettings: GetCurrentUserSession_currentUserSession_customer_colleagues_inheritSettings;
  office: GetCurrentUserSession_currentUserSession_customer_colleagues_office;
  /**
   * The primary user for the customer
   */
  primaryUser: GetCurrentUserSession_currentUserSession_customer_colleagues_primaryUser | null;
  __typename: "Customer";
}

export interface GetCurrentUserSession_currentUserSession_customer_office_company {
  /**
   * The id of company
   */
  id: string;
  __typename: "CustomerCompany";
}

export interface GetCurrentUserSession_currentUserSession_customer_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
  company: GetCurrentUserSession_currentUserSession_customer_office_company;
  __typename: "CustomerOffice";
}

export interface GetCurrentUserSession_currentUserSession_customer_inheritSettings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  __typename: "CustomerSettings";
}

export interface GetCurrentUserSession_currentUserSession_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  settings: GetCurrentUserSession_currentUserSession_customer_settings;
  colleagues: (GetCurrentUserSession_currentUserSession_customer_colleagues | null)[];
  office: GetCurrentUserSession_currentUserSession_customer_office;
  inheritSettings: GetCurrentUserSession_currentUserSession_customer_inheritSettings;
  __typename: "Customer";
}

export interface GetCurrentUserSession_currentUserSession {
  id: string;
  isAuthenticated: boolean;
  client: GetCurrentUserSession_currentUserSession_client;
  user: GetCurrentUserSession_currentUserSession_user | null;
  customer: GetCurrentUserSession_currentUserSession_customer | null;
  __typename: "UserSession";
}

export interface GetCurrentUserSession {
  /**
   * Get current customer
   */
  currentUserSession: GetCurrentUserSession_currentUserSession;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseFolders
// ====================================================

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat_documentType;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_media;
  /**
   * information for the editors
   */
  edit: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_status;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_document;
  alerting: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder | null;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat;
  orders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders[];
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages {
  /**
   * Product Package
   */
  productPackage: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents | null)[];
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer;
  propertiesFuneralStandard: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard;
  summary: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages | null)[];
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer {
  caseFolders: (GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders | null)[] | null;
}

export interface GetCaseFolders {
  /**
   * Get case folders for customer
   */
  findCaseFoldersByCurrentCustomer: GetCaseFolders_findCaseFoldersByCurrentCustomer;
}

export interface GetCaseFoldersVariables {
  touchCaseFolderIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEditOrderPageCaseFolder
// ====================================================

export interface GetEditOrderPageCaseFolder_caseFolder_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_documentFormat_documentType;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_media;
  /**
   * information for the editors
   */
  edit: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_status;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_document;
  alerting: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_documentFormat;
  orders: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders[];
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages {
  /**
   * Product Package
   */
  productPackage: GetEditOrderPageCaseFolder_caseFolder_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: GetEditOrderPageCaseFolder_caseFolder_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (GetEditOrderPageCaseFolder_caseFolder_productPackages_documents | null)[];
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route_editPageRoute {
  clientApp: ApplicationClientApps;
  absUrl: string;
  relUrl: string;
  taps5IframeAbsUrl: string | null;
  taps5RelUrl: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route {
  editPageRoute: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route_editPageRoute;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client {
  route: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
  documentType: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_documentFormat_documentType;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_documentFormat;
  /**
   * media of the order
   */
  media: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_status;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  client: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client | null;
  alerting: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_document;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs {
  id: string;
  name: string;
  isSubTabs: boolean;
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[];
}

export interface GetEditOrderPageCaseFolder_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: GetEditOrderPageCaseFolder_caseFolder_customer;
  propertiesFuneralStandard: GetEditOrderPageCaseFolder_caseFolder_propertiesFuneralStandard;
  summary: GetEditOrderPageCaseFolder_caseFolder_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (GetEditOrderPageCaseFolder_caseFolder_productPackages | null)[];
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[];
}

export interface GetEditOrderPageCaseFolder {
  /**
   * Get caseFolder
   */
  caseFolder: GetEditOrderPageCaseFolder_caseFolder;
}

export interface GetEditOrderPageCaseFolderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGalleryTextGroupsByTags
// ====================================================

export interface getGalleryTextGroupsByTags_galleryTextGroupsByTags_textGroups_items {
  /**
   * Id of the gallerytext
   */
  id: string;
  /**
   * Name of the text
   */
  name: string;
  /**
   * Text as xml
   */
  textXml: string;
  /**
   * Text as plaintext
   */
  textPlain: string;
  /**
   * Author of the text
   */
  author: string;
  /**
   * Tags
   */
  tags: string[];
  /**
   * Internal id
   */
  internalId: number;
  /**
   * Verse is verified of Alis
   */
  isAlisVerified: boolean;
  __typename: "GalleryTextItem";
}

export interface getGalleryTextGroupsByTags_galleryTextGroupsByTags_textGroups {
  /**
   * The total number of texts in the search in this group
   */
  count: number;
  /**
   * Name of the text
   */
  name: string;
  /**
   * Texts
   */
  items: getGalleryTextGroupsByTags_galleryTextGroupsByTags_textGroups_items[];
}

export interface getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts {
  /**
   * Id of the gallerytext
   */
  id: string;
  /**
   * Name of the text
   */
  name: string;
  /**
   * Text as xml
   */
  textXml: string;
  /**
   * Text as plaintext
   */
  textPlain: string;
  /**
   * Author of the text
   */
  author: string;
  /**
   * Tags
   */
  tags: string[];
  /**
   * Internal id
   */
  internalId: number;
  /**
   * Verse is verified of Alis
   */
  isAlisVerified: boolean;
  __typename: "GalleryTextItem";
}

export interface getGalleryTextGroupsByTags_galleryTextGroupsByTags {
  /**
   * The total number of texts in the search
   */
  count: number;
  /**
   * The startindex for the search
   */
  startIndex: number;
  /**
   * Text Groups
   */
  textGroups: getGalleryTextGroupsByTags_galleryTextGroupsByTags_textGroups[];
  /**
   * Text
   */
  texts: getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts[];
  __typename: "GalleryTextItemGroupsResult";
}

export interface getGalleryTextGroupsByTags {
  /**
   * Get text from gallery
   */
  galleryTextGroupsByTags: getGalleryTextGroupsByTags_galleryTextGroupsByTags;
}

export interface getGalleryTextGroupsByTagsVariables {
  input: GalleryTextItemGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGalleryImages
// ====================================================

export interface GetGalleryImages_galleryImagesByTags_images_tagGroups {
  tags: string[];
  __typename: "GalleryImageTagsGroup";
}

export interface GetGalleryImages_galleryImagesByTags_images {
  /**
   * Id of the galleryimage
   */
  id: string;
  /**
   * Public id
   */
  publicId: number;
  /**
   * Thumbnail image
   */
  thumbnail100x80Url: string;
  /**
   * Tag groups
   */
  tagGroups: GetGalleryImages_galleryImagesByTags_images_tagGroups[];
  __typename: "GalleryImageItem";
}

export interface GetGalleryImages_galleryImagesByTags {
  isSupportTags: boolean;
  /**
   * Total av hits in search
   */
  totalImageCount: number;
  startIndex: number;
  actuallyPageLength: number;
  pageLength: number;
  /**
   * Image find
   */
  images: GetGalleryImages_galleryImagesByTags_images[];
  __typename: "GalleryImageAvailableImagesResult";
}

export interface GetGalleryImages {
  /**
   * Get images filter by tags
   */
  galleryImagesByTags: GetGalleryImages_galleryImagesByTags;
}

export interface GetGalleryImagesVariables {
  input: GalleryImageAvailableImagesInput;
  filterTagGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrderById
// ====================================================

export interface GetOrderById_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
  __typename: "Document";
}

export interface GetOrderById_orders_client_route {
  taps4Url: string;
  __typename: "OrderClientRoute";
}

export interface GetOrderById_orders_client {
  mainSystem: SystemFlags;
  isTaps4: boolean;
  isTaps5: boolean;
  route: GetOrderById_orders_client_route;
  __typename: "OrderClient";
}

export interface GetOrderById_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
  __typename: "OrderAlerting";
}

export interface GetOrderById_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
  __typename: "MediaOrderPublishDay";
}

export interface GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
  __typename: "DocumentFormatCollection";
}

export interface GetOrderById_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
  __typename: "DocumentType";
}

export interface GetOrderById_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: GetOrderById_orders_mediaOrder_documentFormat_documentType;
  __typename: "DocumentFormat";
}

export interface GetOrderById_orders_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
  __typename: "MediaOrderPublishConversation";
}

export interface GetOrderById_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
  __typename: "MediaOrderPublishChannels";
}

export interface GetOrderById_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
  __typename: "Size";
}

export interface GetOrderById_orders_mediaOrder_preview_material_basePage {
  size: GetOrderById_orders_mediaOrder_preview_material_basePage_size | null;
  __typename: "MediaOrderMaterialPage";
}

export interface GetOrderById_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface GetOrderById_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: GetOrderById_orders_mediaOrder_preview_material_pages_png;
}

export interface GetOrderById_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: GetOrderById_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (GetOrderById_orders_mediaOrder_preview_material_pages | null)[];
  __typename: "MediaOrderMaterial";
}

export interface GetOrderById_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order
   */
  pricePay: any | null;
  __typename: "PriceDetailScope";
}

export interface GetOrderById_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetOrderById_orders_mediaOrder_preview_price_endCustomer;
  __typename: "PriceDetail";
}

export interface GetOrderById_orders_mediaOrder_preview {
  /**
   * material
   */
  material: GetOrderById_orders_mediaOrder_preview_material;
  __typename: "MediaOrderPreview";
  /**
   * price of the order
   */
  price: GetOrderById_orders_mediaOrder_preview_price | null;
}

export interface GetOrderById_orders_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  __typename: "EditUI";
}

export interface GetOrderById_orders_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
  __typename: "EditInfoResources";
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_entries {
  memoryRoomUrl: string;
  voucherKey: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_case_personTimeBorn {
  value: any | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_case_personTimeDied {
  value: any | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_case {
  personFirstname: string;
  personLastname: string;
  personBornLastname: string;
  personCityDied: string;
  personTimeBorn: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_case_personTimeBorn;
  personTimeDied: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_case_personTimeDied;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_features {
  isLightningCandle: boolean;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage_value {
  id: string;
  imageUrl: string;
  name: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage {
  value: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage_value | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents_images {
  id: string;
  imageUrl: string;
  name: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents {
  welcomeText: string;
  avatarImage: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage | null;
  images: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents_images[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_lastBookingTime {
  value: any | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_time {
  value: any | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies {
  lastBookingTime: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_lastBookingTime;
  time: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_time;
  locationText: string;
  infoText: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_funeral {
  ceremonies: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom {
  id: string;
  entries: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_entries | null;
  case: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_case;
  features: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_features;
  contents: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents;
  funeral: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_funeral;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelLiveStream_advanced {
  playerUrl: string | null;
  ingestionUrl: string | null;
  streamName: string | null;
  RecorderLarix500: string | null;
  RecorderLarix1000: string | null;
  RecorderLarix1500: string | null;
  RecorderLarix2000: string | null;
  GoCoderIOS: string | null;
  GoCoderAndriod: string | null;
  qrRecorderLarix500: string | null;
  qrRecorderLarix1000: string | null;
  qrRecorderLarix1500: string | null;
  qrRecorderLarix2000: string | null;
  qrGoCoderIOS: string | null;
  qrGoCoderAndriod: string | null;
  GoCoderAndroid: string | null;
  __typename: "EditModelLiveStreamAdvanced";
}

export interface GetOrderById_orders_mediaOrder_edit_editModelLiveStream {
  isStored: boolean;
  isActivated: boolean;
  timeLive: any | null;
  waitImageUrl: string | null;
  ceremonyAgendaUrl: string | null;
  advanced: GetOrderById_orders_mediaOrder_edit_editModelLiveStream_advanced;
  __typename: "EditModelLiveStream";
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  margin: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines {
  /**
   * Textline in plantext.
   */
  class: string | null;
  /**
   * Textline in plantext.
   */
  text: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in plantext.
   */
  plainText: string | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Text lines
   */
  lines: (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines | null)[] | null;
  /**
   * Font
   */
  fontSize: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Block styles
   */
  blockStyles: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Common parameters
   */
  common: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  value: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Letter spacing
   */
  letterSpacing: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidth: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  content: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
  __typename: "EditModelAdvertArea";
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage {
  areas: (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
  __typename: "EditModelAdvertBasePage";
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage;
  __typename: "EditModelAdvert";
}

export interface GetOrderById_orders_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
  __typename: "EditHistory";
}

export interface GetOrderById_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface;
  resources: GetOrderById_orders_mediaOrder_edit_resources | null;
  editModelMemoryRoom: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom | null;
  editModelLiveStream: GetOrderById_orders_mediaOrder_edit_editModelLiveStream | null;
  editModelAdvert: GetOrderById_orders_mediaOrder_edit_editModelAdvert | null;
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
  history: GetOrderById_orders_mediaOrder_edit_history;
  __typename: "EditInfo";
}

export interface GetOrderById_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
  __typename: "Media";
}

export interface GetOrderById_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  __typename: "MediaOrderStatus";
}

export interface GetOrderById_orders_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (GetOrderById_orders_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: GetOrderById_orders_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: GetOrderById_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: GetOrderById_orders_mediaOrder_publishChannels;
  /**
   * Preview of the order
   */
  preview: GetOrderById_orders_mediaOrder_preview;
  /**
   * information for the editors
   */
  edit: GetOrderById_orders_mediaOrder_edit;
  /**
   * media of the order
   */
  media: GetOrderById_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: GetOrderById_orders_mediaOrder_status;
  __typename: "MediaOrder";
}

export interface GetOrderById_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: GetOrderById_orders_document;
  client: GetOrderById_orders_client | null;
  alerting: GetOrderById_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetOrderById_orders_mediaOrder | null;
  __typename: "Order";
}

export interface GetOrderById {
  /**
   * Get orders
   */
  orders: GetOrderById_orders[];
}

export interface GetOrderByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPublishOrders
// ====================================================

export interface GetPublishOrders_caseFolder_documents_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
}

export interface GetPublishOrders_caseFolder_documents_summary_price_endCustomer {
  /**
   * Whats pay for the order
   */
  pricePay: any | null;
}

export interface GetPublishOrders_caseFolder_documents_summary_price {
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetPublishOrders_caseFolder_documents_summary_price_endCustomer;
}

export interface GetPublishOrders_caseFolder_documents_summary {
  /**
   * pricesummary of the order
   */
  price: GetPublishOrders_caseFolder_documents_summary_price;
}

export interface GetPublishOrders_caseFolder_documents_orders_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
  /**
   * Order is alread
   */
  isBookingSent: boolean;
  /**
   * Order is in production
   */
  isActivated: boolean;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_basePage {
  size: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_pages_png;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_pages | null)[];
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_price_endCustomer;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview {
  /**
   * material
   */
  material: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_price | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  /**
   * Size in Columns
   */
  sizeColumns: number | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: GetPublishOrders_caseFolder_documents_orders_mediaOrder_media;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishChannels;
  /**
   * Preview of the order
   */
  preview: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview;
  /**
   * documentformat of the order
   */
  documentFormat: GetPublishOrders_caseFolder_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetPublishOrders_caseFolder_documents_orders_mediaOrder_status;
}

export interface GetPublishOrders_caseFolder_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * caseFolder of the order
   */
  caseFolder: GetPublishOrders_caseFolder_documents_orders_caseFolder;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetPublishOrders_caseFolder_documents_orders_mediaOrder | null;
}

export interface GetPublishOrders_caseFolder_documents_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetPublishOrders_caseFolder_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetPublishOrders_caseFolder_documents_documentFormat_documentType;
}

export interface GetPublishOrders_caseFolder_documents {
  /**
   * caseFolder of the document
   */
  caseFolder: GetPublishOrders_caseFolder_documents_caseFolder;
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * ordersummary of document
   */
  summary: GetPublishOrders_caseFolder_documents_summary;
  orders: GetPublishOrders_caseFolder_documents_orders[];
  /**
   * format of the document
   */
  documentFormat: GetPublishOrders_caseFolder_documents_documentFormat;
}

export interface GetPublishOrders_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  documents: (GetPublishOrders_caseFolder_documents | null)[];
}

export interface GetPublishOrders {
  /**
   * Get caseFolder
   */
  caseFolder: GetPublishOrders_caseFolder;
}

export interface GetPublishOrdersVariables {
  caseFolderId: string;
  documentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AreaFragment
// ====================================================

export interface AreaFragment_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface AreaFragment_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface AreaFragment_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  margin: AreaFragment_content_value_rows_columns_item_common_margin | null;
  size: AreaFragment_content_value_rows_columns_item_common_size | null;
}

export interface AreaFragment_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
}

export interface AreaFragment_content_value_rows_columns_item_text_lines {
  /**
   * Textline in plantext.
   */
  class: string | null;
  /**
   * Textline in plantext.
   */
  text: string;
}

export interface AreaFragment_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text {
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in plantext.
   */
  plainText: string | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Text lines
   */
  lines: (AreaFragment_content_value_rows_columns_item_text_lines | null)[] | null;
  /**
   * Font
   */
  fontSize: AreaFragment_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: AreaFragment_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: AreaFragment_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: AreaFragment_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface AreaFragment_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface AreaFragment_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
}

export interface AreaFragment_content_value_rows_columns_item {
  /**
   * Common
   */
  common: AreaFragment_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: AreaFragment_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: AreaFragment_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: AreaFragment_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (AreaFragment_content_value_rows_columns_item_fields | null)[] | null;
}

export interface AreaFragment_content_value_rows_columns {
  item: AreaFragment_content_value_rows_columns_item;
}

export interface AreaFragment_content_value_rows {
  columns: AreaFragment_content_value_rows_columns[];
}

export interface AreaFragment_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: AreaFragment_content_value_rows[];
}

export interface AreaFragment_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface AreaFragment_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Block styles
   */
  blockStyles: AreaFragment_content_editor_common_blockStyles[];
}

export interface AreaFragment_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface AreaFragment_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface AreaFragment_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface AreaFragment_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface AreaFragment_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface AreaFragment_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: AreaFragment_content_editor_text_allowedCharacterGalleries_items[];
}

export interface AreaFragment_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface AreaFragment_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: AreaFragment_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: AreaFragment_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: AreaFragment_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: AreaFragment_content_editor_text_allowedLineHeightInterval;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: AreaFragment_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: AreaFragment_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface AreaFragment_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
}

export interface AreaFragment_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface AreaFragment_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Common parameters
   */
  common: AreaFragment_content_editor_common;
  /**
   * Text parameters
   */
  text: AreaFragment_content_editor_text | null;
  /**
   * Image parameters
   */
  image: AreaFragment_content_editor_image | null;
  /**
   * Help properties
   */
  help: AreaFragment_content_editor_help | null;
}

export interface AreaFragment_content {
  value: AreaFragment_content_value;
  editor: AreaFragment_content_editor | null;
}

export interface AreaFragment_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface AreaFragment_style_content {
  /**
   * Size limit
   */
  size: AreaFragment_style_content_size | null;
}

export interface AreaFragment_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: AreaFragment_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: AreaFragment_style_text_lineHeight | null;
  /**
   * Letter spacing
   */
  letterSpacing: AreaFragment_style_text_letterSpacing | null;
  textScaleWidth: AreaFragment_style_text_textScaleWidth;
}

export interface AreaFragment_style {
  content: AreaFragment_style_content;
  /**
   * Text settings
   */
  text: AreaFragment_style_text;
}

export interface AreaFragment_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface AreaFragment {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: AreaFragment_content;
  style: AreaFragment_style;
  rectangle: AreaFragment_rectangle | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DashboardCaseFolderFragment
// ====================================================

export interface DashboardCaseFolderFragment_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface DashboardCaseFolderFragment_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface DashboardCaseFolderFragment_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface DashboardCaseFolderFragment_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface DashboardCaseFolderFragment_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface DashboardCaseFolderFragment_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface DashboardCaseFolderFragment_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: DashboardCaseFolderFragment_productPackages_documents_documentFormat_documentType;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_media;
  /**
   * information for the editors
   */
  edit: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_status;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: DashboardCaseFolderFragment_productPackages_documents_orders_document;
  alerting: DashboardCaseFolderFragment_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder | null;
}

export interface DashboardCaseFolderFragment_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: DashboardCaseFolderFragment_productPackages_documents_documentFormat;
  orders: DashboardCaseFolderFragment_productPackages_documents_orders[];
}

export interface DashboardCaseFolderFragment_productPackages {
  /**
   * Product Package
   */
  productPackage: DashboardCaseFolderFragment_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: DashboardCaseFolderFragment_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (DashboardCaseFolderFragment_productPackages_documents | null)[];
}

export interface DashboardCaseFolderFragment {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: DashboardCaseFolderFragment_customer;
  propertiesFuneralStandard: DashboardCaseFolderFragment_propertiesFuneralStandard;
  summary: DashboardCaseFolderFragment_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (DashboardCaseFolderFragment_productPackages | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: editModelAdvertFragment
// ====================================================

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  margin: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_lines {
  /**
   * Textline in plantext.
   */
  class: string | null;
  /**
   * Textline in plantext.
   */
  text: string;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in plantext.
   */
  plainText: string | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Text lines
   */
  lines: (editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_lines | null)[] | null;
  /**
   * Font
   */
  fontSize: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (editModelAdvertFragment_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows_columns {
  item: editModelAdvertFragment_basePage_areas_content_value_rows_columns_item;
}

export interface editModelAdvertFragment_basePage_areas_content_value_rows {
  columns: editModelAdvertFragment_basePage_areas_content_value_rows_columns[];
}

export interface editModelAdvertFragment_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: editModelAdvertFragment_basePage_areas_content_value_rows[];
}

export interface editModelAdvertFragment_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface editModelAdvertFragment_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Block styles
   */
  blockStyles: editModelAdvertFragment_basePage_areas_content_editor_common_blockStyles[];
}

export interface editModelAdvertFragment_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface editModelAdvertFragment_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface editModelAdvertFragment_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface editModelAdvertFragment_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface editModelAdvertFragment_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface editModelAdvertFragment_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: editModelAdvertFragment_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface editModelAdvertFragment_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface editModelAdvertFragment_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: editModelAdvertFragment_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: editModelAdvertFragment_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: editModelAdvertFragment_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: editModelAdvertFragment_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: editModelAdvertFragment_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: editModelAdvertFragment_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface editModelAdvertFragment_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
}

export interface editModelAdvertFragment_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface editModelAdvertFragment_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Common parameters
   */
  common: editModelAdvertFragment_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: editModelAdvertFragment_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: editModelAdvertFragment_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: editModelAdvertFragment_basePage_areas_content_editor_help | null;
}

export interface editModelAdvertFragment_basePage_areas_content {
  value: editModelAdvertFragment_basePage_areas_content_value;
  editor: editModelAdvertFragment_basePage_areas_content_editor | null;
}

export interface editModelAdvertFragment_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface editModelAdvertFragment_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: editModelAdvertFragment_basePage_areas_style_content_size | null;
}

export interface editModelAdvertFragment_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface editModelAdvertFragment_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface editModelAdvertFragment_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface editModelAdvertFragment_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface editModelAdvertFragment_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: editModelAdvertFragment_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: editModelAdvertFragment_basePage_areas_style_text_lineHeight | null;
  /**
   * Letter spacing
   */
  letterSpacing: editModelAdvertFragment_basePage_areas_style_text_letterSpacing | null;
  textScaleWidth: editModelAdvertFragment_basePage_areas_style_text_textScaleWidth;
}

export interface editModelAdvertFragment_basePage_areas_style {
  content: editModelAdvertFragment_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: editModelAdvertFragment_basePage_areas_style_text;
}

export interface editModelAdvertFragment_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface editModelAdvertFragment_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: editModelAdvertFragment_basePage_areas_content;
  style: editModelAdvertFragment_basePage_areas_style;
  rectangle: editModelAdvertFragment_basePage_areas_rectangle | null;
  __typename: "EditModelAdvertArea";
}

export interface editModelAdvertFragment_basePage {
  areas: (editModelAdvertFragment_basePage_areas | null)[] | null;
  __typename: "EditModelAdvertBasePage";
}

export interface editModelAdvertFragment {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: editModelAdvertFragment_basePage;
  __typename: "EditModelAdvert";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: editModelLiveStreamFragment
// ====================================================

export interface editModelLiveStreamFragment_advanced {
  playerUrl: string | null;
  ingestionUrl: string | null;
  streamName: string | null;
  RecorderLarix500: string | null;
  RecorderLarix1000: string | null;
  RecorderLarix1500: string | null;
  RecorderLarix2000: string | null;
  GoCoderIOS: string | null;
  GoCoderAndriod: string | null;
  qrRecorderLarix500: string | null;
  qrRecorderLarix1000: string | null;
  qrRecorderLarix1500: string | null;
  qrRecorderLarix2000: string | null;
  qrGoCoderIOS: string | null;
  qrGoCoderAndriod: string | null;
  GoCoderAndroid: string | null;
  __typename: "EditModelLiveStreamAdvanced";
}

export interface editModelLiveStreamFragment {
  isStored: boolean;
  isActivated: boolean;
  timeLive: any | null;
  waitImageUrl: string | null;
  ceremonyAgendaUrl: string | null;
  advanced: editModelLiveStreamFragment_advanced;
  __typename: "EditModelLiveStream";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: editModelMemoryRoomFragment
// ====================================================

export interface editModelMemoryRoomFragment_entries {
  memoryRoomUrl: string;
  voucherKey: string;
}

export interface editModelMemoryRoomFragment_case_personTimeBorn {
  value: any | null;
}

export interface editModelMemoryRoomFragment_case_personTimeDied {
  value: any | null;
}

export interface editModelMemoryRoomFragment_case {
  personFirstname: string;
  personLastname: string;
  personBornLastname: string;
  personCityDied: string;
  personTimeBorn: editModelMemoryRoomFragment_case_personTimeBorn;
  personTimeDied: editModelMemoryRoomFragment_case_personTimeDied;
}

export interface editModelMemoryRoomFragment_features {
  isLightningCandle: boolean;
}

export interface editModelMemoryRoomFragment_contents_avatarImage_value {
  id: string;
  imageUrl: string;
  name: string;
}

export interface editModelMemoryRoomFragment_contents_avatarImage {
  value: editModelMemoryRoomFragment_contents_avatarImage_value | null;
}

export interface editModelMemoryRoomFragment_contents_images {
  id: string;
  imageUrl: string;
  name: string;
}

export interface editModelMemoryRoomFragment_contents {
  welcomeText: string;
  avatarImage: editModelMemoryRoomFragment_contents_avatarImage | null;
  images: editModelMemoryRoomFragment_contents_images[];
}

export interface editModelMemoryRoomFragment_funeral_ceremonies_lastBookingTime {
  value: any | null;
}

export interface editModelMemoryRoomFragment_funeral_ceremonies_time {
  value: any | null;
}

export interface editModelMemoryRoomFragment_funeral_ceremonies {
  lastBookingTime: editModelMemoryRoomFragment_funeral_ceremonies_lastBookingTime;
  time: editModelMemoryRoomFragment_funeral_ceremonies_time;
  locationText: string;
  infoText: string;
}

export interface editModelMemoryRoomFragment_funeral {
  ceremonies: editModelMemoryRoomFragment_funeral_ceremonies[];
}

export interface editModelMemoryRoomFragment {
  id: string;
  entries: editModelMemoryRoomFragment_entries | null;
  case: editModelMemoryRoomFragment_case;
  features: editModelMemoryRoomFragment_features;
  contents: editModelMemoryRoomFragment_contents;
  funeral: editModelMemoryRoomFragment_funeral;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditOrderPageCaseFolderFragment
// ====================================================

export interface EditOrderPageCaseFolderFragment_editTabs_orders_client_route_editPageRoute {
  clientApp: ApplicationClientApps;
  absUrl: string;
  relUrl: string;
  taps5IframeAbsUrl: string | null;
  taps5RelUrl: string;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_client_route {
  editPageRoute: EditOrderPageCaseFolderFragment_editTabs_orders_client_route_editPageRoute;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_client {
  route: EditOrderPageCaseFolderFragment_editTabs_orders_client_route;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
  documentType: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_documentFormat_documentType;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_documentFormat;
  /**
   * media of the order
   */
  media: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_status;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  client: EditOrderPageCaseFolderFragment_editTabs_orders_client | null;
  alerting: EditOrderPageCaseFolderFragment_editTabs_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: EditOrderPageCaseFolderFragment_editTabs_orders_document;
}

export interface EditOrderPageCaseFolderFragment_editTabs {
  id: string;
  name: string;
  isSubTabs: boolean;
  orders: EditOrderPageCaseFolderFragment_editTabs_orders[];
}

export interface EditOrderPageCaseFolderFragment {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  editTabs: EditOrderPageCaseFolderFragment_editTabs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderEditorContentValueFragment
// ====================================================

export interface OrderEditorContentValueFragment_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  margin: OrderEditorContentValueFragment_rows_columns_item_common_margin | null;
  size: OrderEditorContentValueFragment_rows_columns_item_common_size | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_lines {
  /**
   * Textline in plantext.
   */
  class: string | null;
  /**
   * Textline in plantext.
   */
  text: string;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text {
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in plantext.
   */
  plainText: string | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Text lines
   */
  lines: (OrderEditorContentValueFragment_rows_columns_item_text_lines | null)[] | null;
  /**
   * Font
   */
  fontSize: OrderEditorContentValueFragment_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: OrderEditorContentValueFragment_rows_columns_item_text_lineHeight | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: OrderEditorContentValueFragment_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: OrderEditorContentValueFragment_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item {
  /**
   * Common
   */
  common: OrderEditorContentValueFragment_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: OrderEditorContentValueFragment_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: OrderEditorContentValueFragment_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: OrderEditorContentValueFragment_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (OrderEditorContentValueFragment_rows_columns_item_fields | null)[] | null;
}

export interface OrderEditorContentValueFragment_rows_columns {
  item: OrderEditorContentValueFragment_rows_columns_item;
}

export interface OrderEditorContentValueFragment_rows {
  columns: OrderEditorContentValueFragment_rows_columns[];
}

export interface OrderEditorContentValueFragment {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: OrderEditorContentValueFragment_rows[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderFragment
// ====================================================

export interface OrderFragment_document {
  /**
   * Id of the document
   */
  id: string | null;
  __typename: "Document";
}

export interface OrderFragment_client_route {
  taps4Url: string;
  __typename: "OrderClientRoute";
}

export interface OrderFragment_client {
  mainSystem: SystemFlags;
  isTaps4: boolean;
  isTaps5: boolean;
  route: OrderFragment_client_route;
  __typename: "OrderClient";
}

export interface OrderFragment_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
  __typename: "OrderAlerting";
}

export interface OrderFragment_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
  __typename: "MediaOrderPublishDay";
}

export interface OrderFragment_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
  __typename: "DocumentFormatCollection";
}

export interface OrderFragment_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
  __typename: "DocumentType";
}

export interface OrderFragment_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: OrderFragment_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: OrderFragment_mediaOrder_documentFormat_documentType;
  __typename: "DocumentFormat";
}

export interface OrderFragment_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
  __typename: "MediaOrderPublishConversation";
}

export interface OrderFragment_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
  __typename: "MediaOrderPublishChannels";
}

export interface OrderFragment_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
  __typename: "Size";
}

export interface OrderFragment_mediaOrder_preview_material_basePage {
  size: OrderFragment_mediaOrder_preview_material_basePage_size | null;
  __typename: "MediaOrderMaterialPage";
}

export interface OrderFragment_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface OrderFragment_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: OrderFragment_mediaOrder_preview_material_pages_png;
}

export interface OrderFragment_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: OrderFragment_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (OrderFragment_mediaOrder_preview_material_pages | null)[];
  __typename: "MediaOrderMaterial";
}

export interface OrderFragment_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order
   */
  pricePay: any | null;
  __typename: "PriceDetailScope";
}

export interface OrderFragment_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: OrderFragment_mediaOrder_preview_price_endCustomer;
  __typename: "PriceDetail";
}

export interface OrderFragment_mediaOrder_preview {
  /**
   * material
   */
  material: OrderFragment_mediaOrder_preview_material;
  __typename: "MediaOrderPreview";
  /**
   * price of the order
   */
  price: OrderFragment_mediaOrder_preview_price | null;
}

export interface OrderFragment_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  __typename: "EditUI";
}

export interface OrderFragment_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
  __typename: "EditInfoResources";
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_entries {
  memoryRoomUrl: string;
  voucherKey: string;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_case_personTimeBorn {
  value: any | null;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_case_personTimeDied {
  value: any | null;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_case {
  personFirstname: string;
  personLastname: string;
  personBornLastname: string;
  personCityDied: string;
  personTimeBorn: OrderFragment_mediaOrder_edit_editModelMemoryRoom_case_personTimeBorn;
  personTimeDied: OrderFragment_mediaOrder_edit_editModelMemoryRoom_case_personTimeDied;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_features {
  isLightningCandle: boolean;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage_value {
  id: string;
  imageUrl: string;
  name: string;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage {
  value: OrderFragment_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage_value | null;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_contents_images {
  id: string;
  imageUrl: string;
  name: string;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_contents {
  welcomeText: string;
  avatarImage: OrderFragment_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage | null;
  images: OrderFragment_mediaOrder_edit_editModelMemoryRoom_contents_images[];
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_lastBookingTime {
  value: any | null;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_time {
  value: any | null;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies {
  lastBookingTime: OrderFragment_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_lastBookingTime;
  time: OrderFragment_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_time;
  locationText: string;
  infoText: string;
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom_funeral {
  ceremonies: OrderFragment_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies[];
}

export interface OrderFragment_mediaOrder_edit_editModelMemoryRoom {
  id: string;
  entries: OrderFragment_mediaOrder_edit_editModelMemoryRoom_entries | null;
  case: OrderFragment_mediaOrder_edit_editModelMemoryRoom_case;
  features: OrderFragment_mediaOrder_edit_editModelMemoryRoom_features;
  contents: OrderFragment_mediaOrder_edit_editModelMemoryRoom_contents;
  funeral: OrderFragment_mediaOrder_edit_editModelMemoryRoom_funeral;
}

export interface OrderFragment_mediaOrder_edit_editModelLiveStream_advanced {
  playerUrl: string | null;
  ingestionUrl: string | null;
  streamName: string | null;
  RecorderLarix500: string | null;
  RecorderLarix1000: string | null;
  RecorderLarix1500: string | null;
  RecorderLarix2000: string | null;
  GoCoderIOS: string | null;
  GoCoderAndriod: string | null;
  qrRecorderLarix500: string | null;
  qrRecorderLarix1000: string | null;
  qrRecorderLarix1500: string | null;
  qrRecorderLarix2000: string | null;
  qrGoCoderIOS: string | null;
  qrGoCoderAndriod: string | null;
  GoCoderAndroid: string | null;
  __typename: "EditModelLiveStreamAdvanced";
}

export interface OrderFragment_mediaOrder_edit_editModelLiveStream {
  isStored: boolean;
  isActivated: boolean;
  timeLive: any | null;
  waitImageUrl: string | null;
  ceremonyAgendaUrl: string | null;
  advanced: OrderFragment_mediaOrder_edit_editModelLiveStream_advanced;
  __typename: "EditModelLiveStream";
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  margin: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines {
  /**
   * Textline in plantext.
   */
  class: string | null;
  /**
   * Textline in plantext.
   */
  text: string;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in plantext.
   */
  plainText: string | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Text lines
   */
  lines: (OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines | null)[] | null;
  /**
   * Font
   */
  fontSize: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Block styles
   */
  blockStyles: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Common parameters
   */
  common: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  value: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Letter spacing
   */
  letterSpacing: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidth: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  content: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
  __typename: "EditModelAdvertArea";
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage {
  areas: (OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
  __typename: "EditModelAdvertBasePage";
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: OrderFragment_mediaOrder_edit_editModelAdvert_basePage;
  __typename: "EditModelAdvert";
}

export interface OrderFragment_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
  __typename: "EditHistory";
}

export interface OrderFragment_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: OrderFragment_mediaOrder_edit_userInterface;
  resources: OrderFragment_mediaOrder_edit_resources | null;
  editModelMemoryRoom: OrderFragment_mediaOrder_edit_editModelMemoryRoom | null;
  editModelLiveStream: OrderFragment_mediaOrder_edit_editModelLiveStream | null;
  editModelAdvert: OrderFragment_mediaOrder_edit_editModelAdvert | null;
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
  history: OrderFragment_mediaOrder_edit_history;
  __typename: "EditInfo";
}

export interface OrderFragment_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
  __typename: "Media";
}

export interface OrderFragment_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  __typename: "MediaOrderStatus";
}

export interface OrderFragment_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (OrderFragment_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: OrderFragment_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: OrderFragment_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: OrderFragment_mediaOrder_publishChannels;
  /**
   * Preview of the order
   */
  preview: OrderFragment_mediaOrder_preview;
  /**
   * information for the editors
   */
  edit: OrderFragment_mediaOrder_edit;
  /**
   * media of the order
   */
  media: OrderFragment_mediaOrder_media;
  /**
   * Status of the order
   */
  status: OrderFragment_mediaOrder_status;
  __typename: "MediaOrder";
}

export interface OrderFragment {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: OrderFragment_document;
  client: OrderFragment_client | null;
  alerting: OrderFragment_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: OrderFragment_mediaOrder | null;
  __typename: "Order";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PagesFragment
// ====================================================

export interface PagesFragment_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface PagesFragment {
  /**
   * Png material
   */
  png: PagesFragment_png;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: previewImageFragment
// ====================================================

export interface previewImageFragment_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
  __typename: "Size";
}

export interface previewImageFragment_material_basePage {
  size: previewImageFragment_material_basePage_size | null;
  __typename: "MediaOrderMaterialPage";
}

export interface previewImageFragment_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface previewImageFragment_material_pages {
  /**
   * Png material
   */
  png: previewImageFragment_material_pages_png;
}

export interface previewImageFragment_material {
  /**
   * base page
   */
  basePage: previewImageFragment_material_basePage;
  /**
   * pages
   */
  pages: (previewImageFragment_material_pages | null)[];
  __typename: "MediaOrderMaterial";
}

export interface previewImageFragment {
  /**
   * material
   */
  material: previewImageFragment_material;
  __typename: "MediaOrderPreview";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PropertiesFuneralStandardFragment
// ====================================================

export interface PropertiesFuneralStandardFragment {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RectangleFragment
// ====================================================

export interface RectangleFragment_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface RectangleFragment {
  rectangle: RectangleFragment_rectangle | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpdateCaseFolderResponseFragment
// ====================================================

export interface UpdateCaseFolderResponseFragment_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface UpdateCaseFolderResponseFragment_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
}

export interface UpdateCaseFolderResponseFragment_productPackages {
  /**
   * Product Package
   */
  productPackage: UpdateCaseFolderResponseFragment_productPackages_productPackage;
}

export interface UpdateCaseFolderResponseFragment_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface UpdateCaseFolderResponseFragment {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: UpdateCaseFolderResponseFragment_customer;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (UpdateCaseFolderResponseFragment_productPackages | null)[];
  propertiesFuneralStandard: UpdateCaseFolderResponseFragment_propertiesFuneralStandard;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: userInterfaceFragment
// ====================================================

export interface userInterfaceFragment {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  __typename: "EditUI";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SettingsFragment
// ====================================================

export interface SettingsFragment {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  __typename: "CustomerSettings";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomerUserFragment
// ====================================================

export interface CustomerUserFragment_inheritSettings {
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  __typename: "CustomerSettings";
}

export interface CustomerUserFragment_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
  __typename: "CustomerOffice";
}

export interface CustomerUserFragment_primaryUser {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * The username of user
   */
  username: string;
  /**
   * The account is enabled or not
   */
  enabled: boolean;
  __typename: "User";
}

export interface CustomerUserFragment {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  /**
   * The firstname of customer
   */
  firstName: string;
  /**
   * The lastname of customer
   */
  lastName: string;
  inheritSettings: CustomerUserFragment_inheritSettings;
  office: CustomerUserFragment_office;
  /**
   * The primary user for the customer
   */
  primaryUser: CustomerUserFragment_primaryUser | null;
  __typename: "Customer";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PreviewImageFragment
// ====================================================

export interface PreviewImageFragment_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface PreviewImageFragment_material_basePage {
  size: PreviewImageFragment_material_basePage_size | null;
}

export interface PreviewImageFragment_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface PreviewImageFragment_material_pages {
  /**
   * Png material
   */
  png: PreviewImageFragment_material_pages_png;
}

export interface PreviewImageFragment_material {
  /**
   * base page
   */
  basePage: PreviewImageFragment_material_basePage;
  /**
   * pages
   */
  pages: (PreviewImageFragment_material_pages | null)[];
}

export interface PreviewImageFragment {
  /**
   * material
   */
  material: PreviewImageFragment_material;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationClientApps {
  NONE = "NONE",
  TAPS_4 = "TAPS_4",
  TAPS_4_MEDIA_BOOKING = "TAPS_4_MEDIA_BOOKING",
  TAPS_5 = "TAPS_5",
  TAPS_PK_4 = "TAPS_PK_4",
  UNKNOWN = "UNKNOWN",
}

/**
 * What kind of documentfolders will be
 */
export enum CaseFolderCustomerScope {
  Company = "Company",
  Customer = "Customer",
  Default = "Default",
  Office = "Office",
}

/**
 * Customer scope
 */
export enum CustomerScope {
  ALL = "ALL",
  COMPANY = "COMPANY",
  COMPANY_GROUP = "COMPANY_GROUP",
  CUSTOMER = "CUSTOMER",
  DEFAULT = "DEFAULT",
  OFFICE = "OFFICE",
}

/**
 * Rules for merging price
 */
export enum CustomerSettingOrderPriceEndCustomerRules {
  ORDER = "ORDER",
  ORDER_AND_SERVICES = "ORDER_AND_SERVICES",
  ORDER_AND_SERVICES_IF_MAIN = "ORDER_AND_SERVICES_IF_MAIN",
}

export enum EditModelAdvertAreaContentEditorBehaviours {
  GALLERY_IMAGE = "GALLERY_IMAGE",
  GALLERY_SEPARATOR = "GALLERY_SEPARATOR",
  IMAGE = "IMAGE",
  NONE = "NONE",
  ORDER_IMAGE = "ORDER_IMAGE",
  SEPARATOR = "SEPARATOR",
  TEXT_HTML = "TEXT_HTML",
  TEXT_HTML_FOOTER = "TEXT_HTML_FOOTER",
  TEXT_HTML_INGRESS = "TEXT_HTML_INGRESS",
  TEXT_HTML_PERSON = "TEXT_HTML_PERSON",
  TEXT_HTML_PERSON_BORN_DATE = "TEXT_HTML_PERSON_BORN_DATE",
  TEXT_HTML_PERSON_BORN_NAME = "TEXT_HTML_PERSON_BORN_NAME",
  TEXT_HTML_PERSON_DIED_DATE = "TEXT_HTML_PERSON_DIED_DATE",
  TEXT_HTML_RELATIVES = "TEXT_HTML_RELATIVES",
  TEXT_HTML_VERSE = "TEXT_HTML_VERSE",
  TEXT_PERSON_BORN = "TEXT_PERSON_BORN",
  TEXT_PERSON_DIED = "TEXT_PERSON_DIED",
  TEXT_PERSON_NAME = "TEXT_PERSON_NAME",
}

export enum EditModelAdvertAreaContentEditorTypes {
  CUSTOM = "CUSTOM",
  GALLERY_IMAGE = "GALLERY_IMAGE",
  NONE = "NONE",
  PROPS = "PROPS",
  SEPARATOR = "SEPARATOR",
  TEXT_HTML = "TEXT_HTML",
}

export enum EditModelAdvertAreaContentItemFieldItemEditors {
  CALENDER = "CALENDER",
  NONE = "NONE",
  TEXT_SINGLE_LINE = "TEXT_SINGLE_LINE",
}

export enum EditModelAdvertAreaContentItemFieldItemTypes {
  DATE_TIME = "DATE_TIME",
  NONE = "NONE",
  STRING = "STRING",
}

export enum EditModelAdvertAreaContentItemSeparatorTypes {
  HORIZONTAL_LINE = "HORIZONTAL_LINE",
  IMAGE = "IMAGE",
  NONE = "NONE",
}

export enum EditModelAdvertAreaContentRepository {
  GALLERY = "GALLERY",
  NONE = "NONE",
  ORDER = "ORDER",
  TEMPLATE = "TEMPLATE",
  USER = "USER",
}

/**
 * Type of text
 */
export enum EditModelAdvertAreaContentTextItemTypes {
  NONE = "NONE",
  TEXT = "TEXT",
}

export enum EditModelMemoryRoomFuneralCermonyTypes {
  FUNERAL = "FUNERAL",
  MEMORIAL = "MEMORIAL",
  NONE = "NONE",
}

export enum EditModelMemoryRoomPublicationAccess {
  NONE = "NONE",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum EditModelMemoryRoomPublicationStatus {
  NONE = "NONE",
  PRODUCTION = "PRODUCTION",
}

export enum EditModelMemoryRoomPublicationTargets {
  DEFAULT = "DEFAULT",
  NONE = "NONE",
}

/**
 * Type of editor
 */
export enum EditModelTypes {
  ADVERT = "ADVERT",
  DEFAULT = "DEFAULT",
  LIVE_STREAM = "LIVE_STREAM",
  MEMORY_ROOM = "MEMORY_ROOM",
  PRINT = "PRINT",
  UNKNOW = "UNKNOW",
}

export enum EditUIEditStatusColor {
  BLACK = "BLACK",
  BLUE = "BLUE",
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
}

export enum EditUIEditStatusIcon {
  LOCKED = "LOCKED",
  NONE = "NONE",
  UNLOCKED = "UNLOCKED",
}

export enum FontStyles {
  BACKSLANT = "BACKSLANT",
  ITALIC = "ITALIC",
  NORMAL = "NORMAL",
  OBELIC = "OBELIC",
}

export enum FontWeights {
  BLACK = "BLACK",
  BOLD = "BOLD",
  EXTRA_BOLD = "EXTRA_BOLD",
  LIGHT = "LIGHT",
  NORMAL = "NORMAL",
  SEMI_BOLD = "SEMI_BOLD",
  THIN = "THIN",
}

export enum MediaOrderPublishhReadyStatus {
  MISSING_PUBLISH_DAYS = "MISSING_PUBLISH_DAYS",
  NONE = "NONE",
  READY = "READY",
  SENDING = "SENDING",
  TO_LATE = "TO_LATE",
  VALIDATION_ERRORS = "VALIDATION_ERRORS",
  WRONG_STATE = "WRONG_STATE",
}

export enum MessageBoxIcons {
  ERROR = "ERROR",
  INFORMATION = "INFORMATION",
  NONE = "NONE",
  WARNING = "WARNING",
}

export enum OrderAlertingType {
  CRITICAL = "CRITICAL",
  NONE = "NONE",
  WARNING = "WARNING",
}

/**
 * Summary status of caseFolder
 */
export enum OrderStatus {
  CANCELED = "CANCELED",
  DELETED = "DELETED",
  EDIT = "EDIT",
  EDIT_CHANGE = "EDIT_CHANGE",
  FAILED = "FAILED",
  NONE = "NONE",
  PRODUCTION = "PRODUCTION",
  PUBLISHED = "PUBLISHED",
  UNSPECIFIED = "UNSPECIFIED",
  WAIT_APPROVAL = "WAIT_APPROVAL",
}

export enum RelativeTypes {
  INTERVAL = "INTERVAL",
  NONE = "NONE",
  PERCENT = "PERCENT",
  VALUE = "VALUE",
}

export enum StatusColors {
  BLUE = "BLUE",
  GRAY = "GRAY",
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
}

export enum SystemFlags {
  TAPS_4 = "TAPS_4",
  TAPS_5 = "TAPS_5",
  TAPS_5_EXTERN = "TAPS_5_EXTERN",
}

export enum TextAlignments {
  CENTER = "CENTER",
  CENTER_LEFT = "CENTER_LEFT",
  DEFAULT = "DEFAULT",
  JUSTIFY = "JUSTIFY",
  JUSTIFY_LAST_CENTER = "JUSTIFY_LAST_CENTER",
  JUSTIFY_LAST_LEFT = "JUSTIFY_LAST_LEFT",
  LEFT = "LEFT",
  LEFT_LEFT_RIGHT_ALTERNATE = "LEFT_LEFT_RIGHT_ALTERNATE",
  LEFT_RIGHT_ALTERNATE = "LEFT_RIGHT_ALTERNATE",
  RIGHT = "RIGHT",
}

export enum UnitTypes {
  MILLIMETER = "MILLIMETER",
  NONE = "NONE",
  PIXEL = "PIXEL",
  POINT = "POINT",
}

export interface AEditModelAdvertAreaContentRowInput {
  extra?: string | null;
  columns: EditModelAdvertAreaContentColumnInput[];
}

export interface CaseFolderFuneralStandardInput {
  personFirstname: string;
  personLastname: string;
  personBornLastname?: string | null;
  personBorn: string;
  personDied: string;
  personFuneral?: any | null;
  personCityBorn?: string | null;
  personCityLive?: string | null;
  personCityDied?: string | null;
  externalId?: string | null;
  memoryCeremonyLastBookingDay?: string | null;
  personFuneralInfoText?: string | null;
  personFuneralLocationText?: string | null;
}

export interface CaseFolderInput {
  id?: string | null;
  customerId?: string | null;
  propertiesFuneralStandard?: CaseFolderFuneralStandardInput | null;
}

export interface EditModelAdvertAreaContentColumnInput {
  item: EditModelAdvertAreaContentItemInput;
}

export interface EditModelAdvertAreaContentItemCommonInput {
  blockStyleAreaNameId?: string | null;
  marginLeftRightOffset?: RelativeUnitInput | null;
  margin?: MarginNullableInput | null;
  size?: SizeLimitNullableInput | null;
}

export interface EditModelAdvertAreaContentItemFieldItemInput {
  name: string;
  header?: string | null;
  value?: string | null;
  type?: EditModelAdvertAreaContentItemFieldItemTypes | null;
  editor?: EditModelAdvertAreaContentItemFieldItemEditors | null;
}

export interface EditModelAdvertAreaContentItemImageInput {
  type?: EditModelAdvertAreaContentRepository | null;
  repository?: EditModelAdvertAreaContentRepository | null;
  imageGalleryId?: string | null;
  imagePublicId?: string | null;
  imageThumbnaill100x80Url?: string | null;
  imageId?: string | null;
  defaultHeightMM?: number | null;
  heightMM?: number | null;
  isSizeFree?: boolean | null;
  uploadData?: string | null;
  uploadFilename?: string | null;
  uploadDataSource?: string | null;
  uploadComment?: string | null;
  uploadPreferedHeightMM?: number | null;
  resolution?: number | null;
}

export interface EditModelAdvertAreaContentItemInput {
  common?: EditModelAdvertAreaContentItemCommonInput | null;
  text?: EditModelAdvertAreaContentItemTextInput | null;
  image?: EditModelAdvertAreaContentItemImageInput | null;
  separator?: EditModelAdvertAreaContentItemSeparatorInput | null;
  fields?: (EditModelAdvertAreaContentItemFieldItemInput | null)[] | null;
  isDemoValue?: boolean | null;
}

export interface EditModelAdvertAreaContentItemSeparatorInput {
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  repository?: EditModelAdvertAreaContentRepository | null;
  imageId?: string | null;
  widthMM?: number | null;
  heightMM?: number | null;
}

export interface EditModelAdvertAreaContentItemTextInput {
  xmlText?: string | null;
  isXmlTextReadonly?: boolean | null;
  plainText?: string | null;
  lines?: (EditModelAdvertAreaContentItemTextParagraphInput | null)[] | null;
  textNoWrap?: boolean | null;
  fontFamily?: string | null;
  type?: EditModelAdvertAreaContentTextItemTypes | null;
  textAlignment?: TextAlignments | null;
  fontSize?: ValueUnitInput | null;
  lineHeight?: ValueUnitInput | null;
  lineHeightEmpty?: ValueUnitInput | null;
  letterSpacing?: ValueUnitInput | null;
  fontStyle?: FontStyles | null;
  fontWeight?: FontWeights | null;
  textScaleWidth?: RelativeUnitInput | null;
  textScaleHeight?: RelativeUnitInput | null;
  textScaleWidthOffset?: RelativeUnitInput | null;
  textScaleWidthTolerance?: MinMaxOptRelativeUnitInput | null;
}

export interface EditModelAdvertAreaContentItemTextParagraphInput {
  text: string;
  class?: string | null;
}

export interface EditModelAdvertAreaContentValueInput {
  id?: string | null;
  templateAreaId?: string | null;
  visible: boolean;
  hasContent: boolean;
  isEditorVisible?: boolean | null;
  rows: AEditModelAdvertAreaContentRowInput[];
}

export interface EditModelInput {
  editModelAdvertAreaContentValues?: (EditModelAdvertAreaContentValueInput | null)[] | null;
  editModelMemoryRoom?: EditModelMemoryRoomInput | null;
  editModelLiveStream?: EditModelLiveStreamInput | null;
}

export interface EditModelLiveStreamInput {
  waitImageBase64?: string | null;
  waitImageFilename?: string | null;
  ceremonyAgendaBase64?: string | null;
  ceremonyAgendaFilename?: string | null;
}

export interface EditModelMemoryRoomCaseInput {
  personFirstname?: string | null;
  personLastname?: string | null;
  personTimeBorn?: NullableDateInput | null;
  personTimeDied?: NullableDateInput | null;
  personBornLastname?: string | null;
  personCityDied?: string | null;
}

export interface EditModelMemoryRoomContentFileInput {
  id: string;
  name: string;
  purpose: string;
  combineKey: string;
  dataLength: number;
}

export interface EditModelMemoryRoomContentFundInput {
  id: string;
  name?: string | null;
}

export interface EditModelMemoryRoomContentImageInput {
  id?: string | null;
  name?: string | null;
  inputContentBase64Encoded?: string | null;
}

export interface EditModelMemoryRoomContentsInput {
  welcomeText?: string | null;
  avatarImage?: NullableEditModelMemoryRoomContentImageInput | null;
  avatarImageFilename?: string | null;
  backgroundImageId?: string | null;
  fundId?: string | null;
  images?: (EditModelMemoryRoomContentImageInput | null)[] | null;
  funds?: (EditModelMemoryRoomContentFundInput | null)[] | null;
  files?: (EditModelMemoryRoomContentFileInput | null)[] | null;
}

export interface EditModelMemoryRoomEntriesInput {
  password?: string | null;
  voucherKey?: string | null;
  memoryRoomUrl?: string | null;
}

export interface EditModelMemoryRoomFeaturesInput {
  isLightningCandle?: boolean | null;
  isMemorialGift?: boolean | null;
  isDeathAnnouncementAdvert?: boolean | null;
  isFuneralInfo?: boolean | null;
  isFlowerShop?: boolean | null;
}

export interface EditModelMemoryRoomFuneralCeremonyInput {
  id?: string | null;
  delete?: boolean | null;
  type?: EditModelMemoryRoomFuneralCermonyTypes | null;
  infoText?: string | null;
  locationText?: string | null;
  position?: string | null;
  time?: NullableDateTimeInput | null;
  lastBookingTime?: NullableDateTimeInput | null;
  fileCeremonyAgendaId?: string | null;
  fileCeremonyAgendaFilename?: string | null;
  fileCeremonyAgendaBase64Encoded?: string | null;
}

export interface EditModelMemoryRoomFuneralInput {
  ceremonies?: (EditModelMemoryRoomFuneralCeremonyInput | null)[] | null;
}

export interface EditModelMemoryRoomInput {
  id: string;
  isDeleted?: boolean | null;
  isHidden?: boolean | null;
  entries?: EditModelMemoryRoomEntriesInput | null;
  publications?: (EditModelMemoryRoomPublicationInput | null)[] | null;
  contents?: EditModelMemoryRoomContentsInput | null;
  features?: EditModelMemoryRoomFeaturesInput | null;
  funeral?: EditModelMemoryRoomFuneralInput | null;
  case?: EditModelMemoryRoomCaseInput | null;
  invitations?: EditModelMemoryRoomInvitationsInput | null;
}

export interface EditModelMemoryRoomInvitationGuestInput {
  id?: string | null;
  name?: string | null;
  postAddress?: string | null;
  postCity?: string | null;
  postCountry?: string | null;
  email?: string | null;
}

export interface EditModelMemoryRoomInvitationsInput {
  guests?: (EditModelMemoryRoomInvitationGuestInput | null)[] | null;
}

export interface EditModelMemoryRoomPublicationInput {
  id?: string | null;
  delete?: boolean | null;
  state?: EditModelMemoryRoomPublicationStatus | null;
  target?: EditModelMemoryRoomPublicationTargets | null;
  access?: EditModelMemoryRoomPublicationAccess | null;
  publicationStart?: NullableDateInput | null;
  publicationEnd?: NullableDateInput | null;
}

export interface GalleryImageAvailableImagesInput {
  tags: string[];
  startIndex?: number | null;
  pageLength?: number | null;
  filterGalleryImageAttributeInternalIds?: (number | null)[] | null;
  filterGalleryImageAttributeNotInternalIds?: (number | null)[] | null;
  galleryImageOption?: string | null;
  isPreview?: boolean | null;
  galleryImageDocumentFormatId?: string | null;
  documentFormatId?: string | null;
  mediaId?: string | null;
  templateAreaId?: string | null;
  tagGroupIds: string[];
}

export interface GalleryImageAvailableTagsInput {
  tags: string[];
  newTag: string;
  documentFormatId?: string | null;
  templateAreaId?: string | null;
  mediaId?: string | null;
  tagGroupIds: string[];
}

export interface GalleryTextItemGroupInput {
  tags: string[];
  filterText: string;
  count?: number | null;
  startIndex?: number | null;
  documentFormatId?: string | null;
  mediaId?: string | null;
  templateAreaId?: string | null;
  filterGalleryPublic?: boolean | null;
  filterGalleryPrivate?: boolean | null;
}

export interface MarginNullableInput {
  left?: number | null;
  top?: number | null;
  right?: number | null;
  bottom?: number | null;
  type: UnitTypes;
}

export interface MaterialProofSetting {
  isSuggestionMediaOrders?: boolean | null;
  isVoucherKey?: boolean | null;
  isMemorizInfoMinnesrummet?: boolean | null;
  isSingleAdverts?: boolean | null;
  isMemorizInfoMinsida?: boolean | null;
  isCaseFeeIncludedInOrders?: boolean | null;
  isCaseFeeIncludedInFirstOrder?: boolean | null;
  isCaseFeeHide?: boolean | null;
}

export interface MediaOrderPublishExecutionInput {
  orderId: string;
  messageToMedia?: string | null;
  codeLate?: string | null;
  mediaSystemOrderNumber?: string | null;
  mediaCustomerSubriberNumber?: string | null;
}

export interface MediaOrderReactivateExecutionInput {
  orderId: string;
}

export interface MediaOrderUnPublishExecutionInput {
  orderId: string;
  messageToMedia?: string | null;
  codeLate?: string | null;
}

export interface MinMaxOptRelativeUnitInput {
  minValue: number;
  maxValue: number;
  optValue: number;
  type: RelativeTypes;
}

export interface MutationDeleteOrderInput {
  orderIds: string[];
}

export interface MutationLockOrderInput {
  orderId: string;
  isLockOrUnlock?: boolean | null;
}

export interface MutationMessagingOrdersMaterialsInput {
  isOrdersProof: boolean;
  isOrderGalleryImages: boolean;
  isOrderPreviewPng: boolean;
  emailToAddress?: string | null;
  emailReplyAddress?: string | null;
  isSendEmailReplyCopy: boolean;
  message?: string | null;
  proofSetting?: MaterialProofSetting | null;
  orderIds: string[];
}

export interface MutationPublishOrdersInput {
  orders: MediaOrderPublishExecutionInput[];
}

export interface MutationReactivateUnPublishOrdersInput {
  orders: MediaOrderReactivateExecutionInput[];
}

export interface MutationUnPublishOrdersInput {
  orders: MediaOrderUnPublishExecutionInput[];
}

export interface MutationUpdateCaseFolderInput {
  caseFolder: CaseFolderInput;
}

export interface MutationUpdateOrderContentInput {
  orderId: string;
  editModel: EditModelInput;
}

export interface MutationUpdateOrderPublishTimesInput {
  orderId: string;
  publishTimes: any[];
}

export interface NullableDateInput {
  value?: any | null;
}

export interface NullableDateTimeInput {
  value?: any | null;
}

export interface NullableEditModelMemoryRoomContentImageInput {
  value?: EditModelMemoryRoomContentImageInput | null;
}

export interface OrderCollectionMessagingInfoInput {
  orderIds: string[];
  email: string;
}

export interface RelativeUnitInput {
  type: RelativeTypes;
  value: number;
}

export interface SizeLimitNullableInput {
  type: UnitTypes;
  minWidth?: number | null;
  minHeight?: number | null;
  maxWidth?: number | null;
  maxHeight?: number | null;
  fixedWidth?: number | null;
  fixedHeight?: number | null;
}

export interface UserCredentialInput {
  username?: string | null;
  password?: string | null;
  tempKey?: string | null;
  tempSessionToken?: string | null;
}

export interface ValueUnitInput {
  value: number;
  type: UnitTypes;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
