import React from "react";
import {
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
  useIonRouter,
} from "@ionic/react";
import {
  EditModelTypes,
  GetEditOrderPageCaseFolder_caseFolder_editTabs,
  GetOrderById_orders_mediaOrder_edit_userInterface,
} from "../../../schema";
import styled from "styled-components";
import { options } from "ionicons/icons";
import { useParams } from "react-router";
import { IParams } from "../../../custom.Interface";
import ProductMenu from "./ProductMenu";

const TabsSelect: React.FC<{
  caseFolderId: string;
  orderId: string | undefined;
  isLocked: boolean;
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined;
  publishTime: Date | undefined;
  editModelType: EditModelTypes;
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[];
  handleOpenZoomRange: () => void;
}> = (props) => {
  const route = useIonRouter();
  const params: IParams = useParams();
  const [selectValue, setSelectValue] = React.useState<string>("");
  const editTabs = props?.editTabs;

  React.useEffect(() => {
    if (params.orderId)
      setTimeout(() => {
        setSelectValue(params.orderId);
      }, 750);
  }, [params.orderId]);

  const handleChangeOption = (e: CustomEvent<SelectChangeEventDetail<any>>) => {
    if(props.editTabs === null || props.editTabs === undefined) return;
    const tab = props.editTabs.filter((tab) => tab.id === e.detail.value);
    setSelectValue(e.detail.value);

    if (tab === null || tab === undefined) return;
    if (tab[0] && tab[0].name === "Begravningsannons") return;

    route.push(
      `/editOrder/c/${props.caseFolderId}/o/${e.detail.value}`,
      "forward",
      "replace"
    );
  };

  return (
    <TabsSelectStyleWrapper>
      <IonSelect
        className="selectItem"
        interface={"popover"}
        interfaceOptions={options}
        onIonChange={(e) => handleChangeOption(e)}
        value={selectValue}
      >
        {editTabs?.map((tab, index) => {
          return (
            <React.Fragment key={index}>
              <IonSelectOption
                className="parentOption"
                value={tab.id}
                disabled={tab.name === "Begravningsannons" || tab.name === "Tackannons"}
              >
                {tab.name}
              </IonSelectOption>

              {tab.isSubTabs &&
                tab.orders.map((order, index) => {
                  return (
                    <IonSelectOption
                      className="childOption"
                      key={index}
                      value={order.id}
                    >
                      - {order.mediaOrder?.media.friendlyName}
                    </IonSelectOption>
                  );
                })}
            </React.Fragment>
          );
        })}
      </IonSelect>
      <ProductMenu
        orderId={props.orderId!}
        isLocked={props.isLocked}
        userInterface={props.userInterface}
        publishTime={props.publishTime}
        caseFolderId={props.caseFolderId}
        editModelType={props.editModelType!}
        handleOpenZoomRange={props.handleOpenZoomRange}
      />
    </TabsSelectStyleWrapper>
  );
};

const TabsSelectStyleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--ion-color-light-tint);
  .selectItem {
    width: 100%;
    height: 100%;
    margin: 3px;
    --background: transparent linear-gradient(180deg, #fff 0%, #f8f8f8 100%) 0%
      0% no-repeat padding-box;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
  }
`;

export default TabsSelect;
