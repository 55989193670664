import gql from "graphql-tag";

const ORDER_EDITOR_CONTENT_VALUE_FRAGMENT = gql`
  fragment OrderEditorContentValueFragment on EditModelAdvertAreaContentValue {
    id
    visible
    hasContent
    isEditorVisible
    templateAreaId
    rows {
      columns {
        item {
          common {
            blockStyleAreaNameId
            margin {
              type
              left
              right
              top
              bottom
            }

            ## annonsen size
            size(unit: MILLIMETER) {
              type
              minWidth
              minHeight
              maxWidth
              maxHeight
              fixedWidth
              fixedHeight
            }
          }
          isDemoValue
          image {
            type
            imageId
            imagePublicId
            imageThumbnaill100x80Url
            heightMM
            defaultHeightMM
            isSizeFree
            uploadData
            uploadFilename
            resolution
            uploadPreferedHeightMM
          }
          text {
            textAlignment
            plainText
            xmlText
            fontFamily
            lines {
              class
              text
            }
            fontSize {
              type
              value
            }
            lineHeight {
              type
              value
            }
            letterSpacing {
              type
              value
            }
            textScaleWidth {
              type
              value
            }
            textNoWrap
          }
          separator {
            type
            imageId
            repository
            widthMM
            heightMM
          }
          fields {
            name
            header
            type
            editor
            value
          }
        }
      }
    }
  }
`;
// ## common {
//   ## margin {
//     ## type
//     ## left
//     ## right
//     ## top
//     ## bottom
//   ## }
// ## }

export default ORDER_EDITOR_CONTENT_VALUE_FRAGMENT;
